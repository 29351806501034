
import { mapState } from 'vuex'

const creator1 = require('~/assets/images/landing-page/creator/rengarciaaa.png')
const creator2 = require('~/assets/images/landing-page/creator/mabuhaymix.png')
const creator3 = require('~/assets/images/landing-page/creator/nelayan.png')
const creator4 = require('~/assets/images/landing-page/creator/zenshiki.png')

const eyeAccordionIcon = require('~/assets/images/landing-page/eye-accordion.svg')
const shape03 = require('~/assets/images/landing-page/shape-03.png')

export default {
  data() {
    return {
      eyeAccordionIcon,
      shape03,
      selectedCreatorTestimony: {},
      sliderSettings: {
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 100,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              touchThreshold: 100,
            },
          },
        ],
      },
      categorySliderIndex: 0,
      transitionState: false,
    }
  },
  computed: {
    ...mapState({
      locale: state => state.settings.locale,
    }),
    testimony() {
      return [
        {
          image: creator1,
          name: 'rengarciaaa',
          tag: [
            this.$t('cosplay'),
            this.$t('photography'),
            this.$t('streaming'),
          ],
          testimony: this.$t('landing_page.testimony.creator1'),
        },
        {
          image: creator2,
          name: 'mabuhaymix',
          tag: [
            this.$t('comedy'),
            this.$t('music'),
          ],
          testimony: this.$t('landing_page.testimony.creator2'),
        },
        {
          image: creator3,
          name: 'Nelayan',
          tag: [
            this.$t('gaming'),
          ],
          testimony: this.$t('landing_page.testimony.creator3'),
        },
        {
          image: creator4,
          name: 'Zenshiki',
          tag: [
            this.$t('gaming'),
            this.$t('streaming'),
            this.$t('vtuber'),
          ],
          testimony: this.$t('landing_page.testimony.creator4'),
        },
      ]
    },
  },
  mounted() {
    this.selectCreatorTestimony(this.testimony[0])
  },
  methods: {
    moreCategories() {
      this.$router.push('/discover')
      this.$gtm.push({
        event: 'landing_category_more',
      })
    },
    selectCreatorTestimony(item, index) {
      this.selectedCreatorTestimony = item
      if (this.$refs.creatorTestimonySlider) {
        this.$refs.creatorTestimonySlider.goTo(index)
      }
      this.$gtm.push({
        event: 'landing_review',
      })
    },
    beforeSlideChange(oldSlideIndex, newSlideIndex) {
      this.selectedCreatorTestimony = this.testimony[newSlideIndex]
      if (oldSlideIndex > newSlideIndex) {
        this.transitionState = true
      } else {
        this.transitionState = false
      }
    },
    afterSlideChange(slideIndex) {
      this.categorySliderIndex = slideIndex
      this.selectedCreatorTestimony = this.testimony[slideIndex]
    },
  },
}
